@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-black text-white;
}

.triangle-up {
    @apply border-yellow;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom-width: 12px;
}

.triangle-down {
    @apply border-yellow;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top-width: 12px;
}

@layer utilities {
    .text-shadow-title {
        text-shadow: 0px 0px 15px #f7df52;
    }

    .object-right-center {
        object-position: right center;
    }
}

.font-montserrat {
    @apply font-montserrat font-extrabold tracking-tight;
}

.font-montserrat-slanted {
    @apply font-montserrat font-extrabold tracking-tight transform skew-x-12;
}
