@font-face {
    font-family: 'Marche Super';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local(''),
        url("fonts/Marche-Super.ttf") format('ttf'),
        url("fonts/Marche-Super.eot") format('eot'),
        url("fonts/Marche-Super.woff2") format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("fonts/Marche-Super.woff") format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
    font-family: 'Marche Super Backslant';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local(''),
        url("fonts/Marche-SuperBackslant.ttf") format('ttf'),
        url("fonts/Marche-SuperBackslant.eot") format('eot'),
        url("fonts/Marche-SuperBackslant.woff2") format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("fonts/Marche-SuperBackslant.woff") format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
